<template>
  <b-modal
    visible
    scrollable
    centered
    :title="title"
    :size="appealType !== 'RejectionReason' ? 'lg' : 'md'"
    no-close-on-backdrop
    @hidden="onClose"
    @ok="onClose"
  >
    <div
      v-if="isLoading"
      class="d-flex justify-content-center w-100"
    >
      <b-spinner variant="success" />
    </div>

    <template v-else>
      <div>
        <b-form-group
          :label="labelCommentOrTemplateName"
          label-class="required"
          :state="!$v.form.name.$error"
          :invalid-feedback="errorsValidation.name[0]"
        >
          <div v-if="!isAppealTypeRejectionReason">
            <b-input
              v-model.trim="$v.form.name.$model"
              placeholder="Введите текст"
              size="sm"
            />
          </div>
          <div v-else>
            <b-form-textarea
              v-model.trim="$v.form.name.$model"
              placeholder="Введите текст"
              rows="1"
              max-rows="5"
              size="sm"
            />
          </div>
        </b-form-group>
        <!-- скрываем весь блок если тип обращения Причина отказа от МИД -->
        <div v-if="!isAppealTypeRejectionReason">
          <b-form-group
            label="Диагноз"
          >
            <b-form-textarea
              v-model="form.reconciliationsDiagnosis"
              placeholder="Введите текст"
              rows="3"
              size="sm"
            />
          </b-form-group>
          <b-form-group
            label="ФИО Врача"
          >
            <b-form-textarea
              v-model="form.reconciliationsDoctorName"
              placeholder="Введите текст"
              :rows="3"
              size="sm"
            />
          </b-form-group>
          <b-form-group
            label="Согласованные услуги"
          >
            <b-form-textarea
              v-model="form.reconciliationsAgreedServices"
              placeholder="Введите текст"
              rows="3"
              size="sm"
            />
          </b-form-group>
          <b-form-group
            label="Несогласованные услуги"
          >
            <b-form-textarea
              v-model="form.reconciliationsNotAgreedServices"
              placeholder="Введите текст"
              rows="3"
              size="sm"
            />
          </b-form-group>
          <b-form-group
            label="Причина отказа"
          >
            <b-form-textarea
              v-model="form.reconciliationsRegectionReason"
              placeholder="Введите текст"
              rows="3"
              size="sm"
            />
          </b-form-group>
          <b-form-group
            label="Комментарий врача"
          >
            <b-form-textarea
              v-model="form.reconciliationsDoctorComment"
              placeholder="Введите текст"
              rows="3"
              size="sm"
            />
          </b-form-group>
        </div>
      </div>
    </template>

    <template #modal-footer>
      <b-button
        variant="danger"
        :type="$const.PRIMARY_BUTTON"
        :disabled="isLoading || isSaving"
        class="float-right"
        @click="onClose"
      >
        Отменить
      </b-button>

      <b-button
        variant="primary"
        :type="$const.PRIMARY_BUTTON"
        :disabled="isLoading || isSaving"
        class="float-right"
        @click="onClickSave"
      >
        Сохранить
        <b-spinner
          v-if="isSaving"
          variant="light"
          small
        />
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import { showValidationErrorMessage, showValidationErrorCustomMessage } from '@/helpers/messages';
import { appealsTemplatesService } from '@/services';
import { required } from 'vuelidate/lib/validators';

export default {
  name: 'AppealTemplateEditModal',
  components: {
  },
  props: {
    modalName: {
      type: [String, Number],
      default: null,
    },
    title: {
      type: String,
      default: 'Создание шаблона согласования',
    },
    templateData: {
      type: Object,
      default: () => ({}),
    },
    appealType: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      isOpen: true,
      isLoading: false,
      isSaving: false,
      form: {
        name: null,
        reconciliationsAgreedServices: '',
        reconciliationsNotAgreedServices: '',
        reconciliationsRegectionReason: '',
        reconciliationsDiagnosis: '',
        reconciliationsDoctorName: '',
        reconciliationsDoctorComment: '',
        rejectionReasonComment: '',
      },
    };
  },
  validations() {
    return {
      form: {
        name: {
          required,
        },
      },
    };
  },
  computed: {
    isEdit() {
      return Object.keys(this.templateData).length;
    },
    errorsValidation() {
      const errors = {};

      errors.name = [];
      if (!this.$v.form.name.required) {
        errors.name.push(`Поле ${this.labelCommentOrTemplateName} обязательно для заполнения`);
      }

      return errors;
    },
    isAppealTypeRejectionReason() {
      return this.appealType === 'RejectionReason';
    },
    labelCommentOrTemplateName() {
      return this.isAppealTypeRejectionReason ? 'Комментарий' : 'Название шаблона';
    },
  },
  created() {
    if (this.isEdit) {
      const newTemplateData = JSON.parse(JSON.stringify(this.templateData));
      this.form = {
        ...newTemplateData.parsedData,
        name: this.templateData.name,
      };
    }
  },

  methods: {
    onClose() {
      this.$emit('input', false);
    },
    async onClickSave() {
      this.$v.$touch();
      if (this.$v.$error) {
        console.log(this.$v.error);
        showValidationErrorMessage();
        return;
      }
      this.isSaving = true;

      // если тип шаблона - отказ от Мид
      if (this.appealType === 'RejectionReason') {
        this.form.rejectionReasonComment = this.form.name;
      }

      try {
        const { name, ...fields } = this.form;
        const data = {
          name,
          text: JSON.stringify(fields),
          type: this.appealType,
        };
        if (this.isEdit) {
          data.id = this.templateData.id;
          data.isActive = this.templateData.isActive;
        }

        if (this.isEdit) {
          const response = await appealsTemplatesService.update(data);
          console.log(response);
        } else {
          const response = await appealsTemplatesService.create(data);
          console.log(response);
        }

        if (this.appealType === 'Reconciliation') {
          this.$store.commit('AppealsTemplates/TOGGLE_FETCH_APPEALS_TEMPLATES_TRIGGER');
        } else {
          this.$store.commit('AppealsTemplates/TOGGLE_FETCH_APPEALS_TEMPLATES_REJECT_TRIGGER');
        }

        this.onClose();
      } catch (e) {
        console.warn(e);
        showValidationErrorCustomMessage('Возможно шаблон с таким названием уже существует');
      } finally {
        this.isSaving = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
